import Img from "gatsby-image";
import * as React from "react";
import { Container, Grid } from "semantic-ui-react";

interface CompanyLogoRowProps {
  asimov: any;
  autodesk: any;
  bu: any;
  corteva: any;
  enEvolv: any;
  nsf: any;
  transcriptic: any;
  synthego: any;
  twist: any;
}

export default ({
  asimov,
  autodesk,
  bu,
  corteva,
  enEvolv,
  nsf,
  synthego,
  transcriptic,
  twist,
}: CompanyLogoRowProps) => (
  <Container className="partner-logo-grid">
    <Grid relaxed stackable columns="equal" verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={2}>
          <Img alt="Asimov" fluid={asimov.childImageSharp.fluid} />
        </Grid.Column>
        {/* <Grid.Column width={2}>
          <Img fluid={autodesk.childImageSharp.fluid} />
        </Grid.Column> */}
        <Grid.Column width={2}>
          <Img alt="BU" fluid={bu.childImageSharp.fluid} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Img alt="Corteva" fluid={corteva.childImageSharp.fluid} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Img alt="EnEvolv" fluid={enEvolv.childImageSharp.fluid} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Img alt="NSF" fluid={nsf.childImageSharp.fluid} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Img alt="Synthego" fluid={synthego.childImageSharp.fluid} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Img alt="Transcriptic" fluid={transcriptic.childImageSharp.fluid} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Img alt="Twist" fluid={twist.childImageSharp.fluid} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
