import { graphql, Link } from "gatsby";
import * as React from "react";
import {
  Card,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react";

import bigData from "../../data/img/graphics/big-data-2019.svg";
import graph from "../../data/img/graphics/design-graph-2019.svg";
import enumeratedDesigns from "../../data/img/graphics/enumerated-designs-2019.svg";
import machineLearning from "../../data/img/graphics/machine-learning-2019.svg";
import robot from "../../data/img/graphics/robot-2019.svg";
import storage from "../../data/img/graphics/storage-2019.svg";
import CompanyLogoRow from "../components/CompanyLogoRow/CompanyLogRow";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { LayoutProps, menuItems, withLayout } from "../components/Layout";

interface IndexPageProps {
  data: {
    asimov: any;
    autodesk: any;
    bu: any;
    corteva: any;
    enEvolv: any;
    nsf: any;
    transcriptic: any;
    synthego: any;
    twist: any;
  };
}

const IndexPage = (props: LayoutProps & IndexPageProps) => {
  return (
    <div>
      <HeaderMenu pathname={props.location.pathname} items={menuItems} />
      {/* Master head */}
      <Segment
        middle="true"
        aligned="true"
        textAlign="center"
        className="masthead home-masthead"
      >
        <Container className="tagline">
          <h1>Software</h1>
          <h2>for synthetic biology</h2>
        </Container>
      </Segment>

      {/* Software overview */}
      <Segment className="stripe alternate software-overview" padded="very">
        <Container text>
          <Header as="h1" textAlign="center">
            Software is part of every engineered living system
          </Header>
          <Header.Subheader>
            Lattice creates design-automation workflows that fundamentally
            change engineering biology.
          </Header.Subheader>
        </Container>

        <Divider hidden />

        <div className="computer large screen widescreen only">
          <Grid
            columns="2"
            stackable
            className="container software-characteristics"
          >
            {Intro}
          </Grid>
        </div>

        <div className="mobile tablet only">
          <Grid
            columns="1"
            stackable
            className="container software-characteristics"
          >
            {Intro}
          </Grid>
        </div>
      </Segment>

      <Container>
        <Divider />
      </Container>

      {/* Discover, Develop, Design, Democratize */}
      <Segment className="stripe engagement-models" padded="very">
        <Container text className="overview">
          <Header as="h1" className="em-header" textAlign="center">
            New Discipline, New Approaches
          </Header>
          <Header.Subheader>
            Pick from a set of engagement models to fit your specific problem,
            budget, and timeline.
          </Header.Subheader>
        </Container>

        <Divider hidden />

        <Container>
          <Grid className="engagements-grid" padded="vertically">
            <Grid.Row>
              <Grid.Column largeScreen={4} mobile={16}>
                <Header as="h3">Discover</Header>
                <p>
                  We work directly with you to create a personalized software
                  roadmap for your bio-design automation needs.
                </p>
              </Grid.Column>

              <Grid.Column largeScreen={4} mobile={16}>
                <Header as="h3">Develop</Header>
                <p>
                  We build fully customized software with flexible ownership
                  models for large and small projects.
                </p>
              </Grid.Column>

              <Grid.Column largeScreen={4} mobile={16}>
                <Header as="h3">Design</Header>
                <p>
                  We license state-of-the-art bio-design automation software
                  configured, deployed, and secured to fit your needs.
                </p>
              </Grid.Column>

              <Grid.Column largeScreen={4} mobile={16}>
                <Header as="h3">Democratize</Header>
                <p>
                  We connect customers to service providers through flexible
                  APIs and engagement models.
                </p>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden />

            <Grid.Row textAlign="center">
              <Link to="/solutions" className="learn-more-button">
                Lattice Solutions
              </Link>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>

      <Container>
        <Divider />
      </Container>

      {/* Quotes from featured engagements */}
      <Segment padded="very" className="testimonials">
        <Container text>
          <Header as="h1" textAlign="center">
            Testimonials
          </Header>
        </Container>

        <Divider hidden />

        <Container className="testimonial-cards-container">
          <Grid>
            <Grid.Column
              className="testimonial-column"
              largeScreen={8}
              mobile={16}
            >
              <Card>
                <Card.Content>
                  <Card.Description>
                    <p>
                      <span>"</span>Lattice is a highly skilled and uniquely
                      capable firm with multitudes of talent on-board. They
                      expertly met our requirements and their delivery on change
                      requests were lightning-fast. I would certainly recommend
                      Lattice as a highly reliable partner to build world-class
                      synthetic biology tools.<span>"</span>
                    </p>
                  </Card.Description>
                  <Divider />
                  <Card.Header>Visu Annaluru, Ph.D.</Card.Header>
                  <Card.Meta className="title">Research Scientist</Card.Meta>
                  <Card.Meta>
                    Corteva Agriscience, Agriculture Division of DowDuPont
                  </Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>

            <Grid.Column
              className="testimonial-column"
              largeScreen={8}
              mobile={16}
            >
              <Card>
                <Card.Content>
                  <Card.Description>
                    <p>
                      <span>"</span>Lattice's expertise in algorithm development
                      for synthetic biology has added tremendous value to
                      enEvolv. Through custom integration of Lattice's codebase
                      with enEvolv algorithms, together we're building the
                      premier next generation platform for design and machine
                      learning from massive libraries of engineered enzymes and
                      strains. Lattice has provided a high level of engagement
                      from day 1 and would be a tremendous complement to any
                      in-house software engineering team.<span>"</span>
                    </p>
                  </Card.Description>
                  <Divider />
                  <Card.Header>Jay Konieczka, Ph.D.</Card.Header>
                  <Card.Meta className="title">Co-founder & COO</Card.Meta>
                  <Card.Meta>enEvolv</Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>

            <Grid.Column
              className="testimonial-column"
              largeScreen={8}
              mobile={16}
            >
              <Card>
                <Card.Content>
                  <Card.Description>
                    <p>
                      <span>"</span>Lattice's ability to provide customized
                      bio-design automation software allowed us to bootstrap our
                      internal automation capabilities much more quickly than
                      would have been normally possible for a company of our
                      size. In addition, we own the code which is important for
                      our long term goals.<span>"</span>
                    </p>
                  </Card.Description>
                  <Divider />
                  <Card.Header>Alec Nielsen, Ph.D.</Card.Header>
                  <Card.Meta className="title">Founder & CEO</Card.Meta>
                  <Card.Meta>Asimov</Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>

            <Grid.Column
              className="testimonial-column"
              largeScreen={8}
              mobile={16}
            >
              <Card>
                <Card.Content>
                  <Card.Description>
                    <p>
                      <span>"</span>The team at Lattice Automation is expert at
                      understanding the needs of the synthetic biology industry
                      - highly intelligent and strategic in their
                      recommendations.<span>"</span>
                    </p>
                  </Card.Description>
                  <Divider />
                  <Card.Header>Emily Leproust, Ph.D.</Card.Header>
                  <Card.Meta className="title">CEO & Co-founder</Card.Meta>
                  <Card.Meta>Twist Bioscience Corporation</Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>

      {/* Partner logos */}
      <Segment className="partner-logo-segment">
        <CompanyLogoRow {...props.data} />
      </Segment>
    </div>
  );
};

// from https://www.gatsbyjs.org/docs/working-with-images/
export const iconImg = graphql`
  fragment iconImg on File {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

// from https://www.gatsbyjs.org/docs/working-with-images/
export const partnerImg = graphql`
  fragment partnerImg on File {
    childImageSharp {
      fluid(maxWidth: 200, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

// from https://www.gatsbyjs.org/docs/working-with-images/
export const graphicsImg = graphql`
  fragment graphicsImg on File {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    bu: file(relativePath: { eq: "img/partners/bu.png" }) {
      ...partnerImg
    }

    autodesk: file(relativePath: { eq: "img/partners/autodesk.png" }) {
      ...partnerImg
    }

    nsf: file(relativePath: { eq: "img/partners/nsf-logo.png" }) {
      ...partnerImg
    }

    synthego: file(relativePath: { eq: "img/partners/synthego.png" }) {
      ...partnerImg
    }

    transcriptic: file(
      relativePath: { eq: "img/partners/transcriptic-logo.png" }
    ) {
      ...partnerImg
    }

    twist: file(relativePath: { eq: "img/partners/twist.png" }) {
      ...partnerImg
    }

    asimov: file(relativePath: { eq: "img/partners/asimov-logo.png" }) {
      ...partnerImg
    }

    corteva: file(relativePath: { eq: "img/partners/corteva-logo.png" }) {
      ...partnerImg
    }

    enEvolv: file(
      relativePath: { eq: "img/partners/enEvolv-logo-noSquares.png" }
    ) {
      ...partnerImg
    }

    bigData: file(relativePath: { eq: "img/graphics/big-data-2019.svg" }) {
      ...graphicsImg
    }

    graph: file(relativePath: { eq: "img/graphics/graph-2019.svg" }) {
      ...graphicsImg
    }

    enumeratedDesigns: file(
      relativePath: { eq: "img/graphics/enumerated-designs-2019.svg" }
    ) {
      ...graphicsImg
    }

    machineLearning: file(
      relativePath: { eq: "img/graphics/machine-learning-2019.svg" }
    ) {
      ...graphicsImg
    }

    robot: file(relativePath: { eq: "img/graphics/robot-2019.svg" }) {
      ...graphicsImg
    }

    storage: file(relativePath: { eq: "img/graphics/storage-2019.svg" }) {
      ...graphicsImg
    }
  }
`;

const Intro = (
  <>
    <Grid.Row>
      <Grid.Column>
        <Image alt="Robotic integration" src={robot} size="small" centered />
        <p>
          Lattice software works with a wide variety of laboratory automation
          and microfluidic platforms to make a seamless transition from the
          computer to the lab.
        </p>
      </Grid.Column>
      <Grid.Column>
        <Image
          alt="Library design"
          src={enumeratedDesigns}
          size="small"
          centered
        />
        <p>
          Lattice creates powerful algorithms for intelligently enumerating
          designs from library elements. Designs are “correct-by-construction”
          and can be tailored to specific libraries, environmental context, and
          applications.
        </p>
      </Grid.Column>
    </Grid.Row>

    <Grid.Row>
      <Grid.Column>
        <Image
          alt="Machine learning"
          src={machineLearning}
          size="small"
          centered
        />
        <p>
          Lattice works with customers to incorporate machine learning
          throughout the specify-design-build-test cycle.
        </p>
      </Grid.Column>
      <Grid.Column>
        <Image alt="Big data" src={bigData} size="small" centered />
        <p>
          Lattice provides analytics so customers can make sense of large data
          sets. We use design of experiments approaches for the rapid tuning of
          large parameter spaces and the intelligent selection of candidate
          designs.
        </p>
      </Grid.Column>
    </Grid.Row>

    <Grid.Row>
      <Grid.Column>
        <Image alt="Design rules" src={graph} size="small" centered />
        <p>
          Lattice provides formalized, domain specific specification languages
          so customers can describe designs and automatically capture rules.
        </p>
      </Grid.Column>
      <Grid.Column>
        <Image alt="BioCAD" src={storage} size="small" centered />
        <p>
          Lattice provides tools to store and share <i>in-silico</i> bio-designs
          with a range of LIMS, databases, and synthesis providers.
        </p>
      </Grid.Column>
    </Grid.Row>
  </>
);

export default withLayout(IndexPage);
